<template>
    <div id="wrappers">
        <!-- top -->
        <div class="topbar clearfix">
            <div class="topbar-left fl">
                <div class="text-center">
                    <a href="/Lecturerend" class="logo">
                        <img src="./../../assets/img/logo.png" alt="" class="img">
                    </a>
                </div>
            </div>
            <div class="text—name fl">
                <span> · </span>讲师端
            </div>
            <div class="topbar-left info-cont fr">
                <div class="u-avatar fr" @mouseover="mouseover()" @mouseout="mouseout()">
                    <div class="fl member">
                        <img class="avatar fl" :src="this.$store.state.user_avatar===null ||this.$store.state.user_avatar==''||this.$store.state.user_avatar=='null'?'https://static.ibeisai.com/image/default_avatar.png':this.$store.state.user_avatar" alt="">
                    </div>
                    <p class="name fl">{{this.$store.state.user_name===null||this.$store.state.user_name===''||this.$store.state.user_name==='null'?'':this.$store.state.user_name}}</p>
                    <ul class="dropdown-menu" v-show="login_acc==true">
                        <li @click="count()">账号设置</li>
                        <li @click="exit()">安全退出</li>
                    </ul>
                </div>
                <!-- <div class="menu_one fr">
                    <el-tooltip class="item" effect="dark" content="项目通知" placement="bottom">
                        <el-popover
                            placement="bottom-end"
                            width="400"
                            trigger="click"
                            popper-class="popoverDiv"
                        >
                            <div>123</div>
                            <i class="iconfont icon-tongzhi"  slot="reference"></i>
                        </el-popover>
                    </el-tooltip>
                </div> -->
                <div class="menu_one fr" v-show="database">
                    <el-tooltip class="item" effect="dark" content="资料库" placement="bottom">
                        <i class="iconfont icon-folder" @click="drawers()"></i>
                    </el-tooltip>
                </div>
            </div>
        </div>
        <!-- center -->
        <div class="center clearfix">
            <router-view @func="getMsgid"></router-view>
        </div>
        <!-- footer -->
        <footer class="footer text-right">
            <span>© 2022. 创项课_v1.2.0  All rights reserved.</span>
        </footer>
        <!-- 资源库抽屉 -->
        <div class="drawerBox">
            <el-drawer
                size="450px"
                title="资料库"
                :visible.sync="drawer"
                :show-close="false"
                :with-header="true">
                <span class="addBox" @click="addBox()">
                    +&nbsp;&nbsp; 添加
                </span>
                <div class="mu_list">
                    <ul v-show="istrue==1">
                        <li v-for="(item,index) in drawList" :key="index" v-show="drawList.length!=0">
                            <div class="bm-drake-box">
                                <div class="mu-item">
                                    <div class="mu-item-left">
                                        <div class="mu-avatar">
                                            <div class="mu-avatar-inner">
                                                <img src="./../../assets/img/ppt.svg" alt="">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mu-item_item clearfix">
                                        <div class="item_left fl">
                                            <span>{{item.name}}</span>
                                        </div>
                                    </div>
                                    <hr class="mu-divider">
                                </div>
                                <div class="mu-icon-menu">
                                    <div style="position: relative;">
                                        <button class="mu-icon-button">
                                            <i class="iconfont icon-more material-icons" @click="deletess()" v-close></i>
                                        </button>
                                        <div class="operateList" v-show="deletes">
                                            <div class="mu-menu-list">
                                                <div class="icon-item" @click="download(item)">
                                                    <i class="iconfont icon-xiazai"></i>
                                                    下载
                                                </div>
                                                <div class="icon-item" @click="deleBtn(item)">
                                                    <i class="iconfont icon-shanchu"></i>
                                                    删除
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <div class="default-wrapper" v-show="drawList.length==0">
                            <img src="./../../assets/img/bookmark.svg" alt="">
                            <p><span>资料库</span></p>
                            <button class="mu-raised-button" @click="addBox()">
                                添加一条
                            </button>
                        </div>
                    </ul>
                    <!-- 添加 -->
                    <div class="addshow" v-show="istrue==2">
                        <div class="add_upload" v-if="fileList.length==0">
                            <el-upload
                                class="upload-demo"
                                drag
                                :show-file-list='false'
                                :action="this.$store.state.uploadimage"
                                :on-success="handleAvatarSuccess"
                                :limit="1"
                                :file-list="fileList"
                                multiple>
                                    <div class="tips-box"><p class="upload-tips"><span>+</span>上传资源<br></p> <p>支持拖拽上传</p></div>
                                    <div class="text-row"><p>支持资源 pdf / word / ppt</p></div>
                            </el-upload>
                        </div>
                        <div class="add_upload" v-else>
                            <div class="uploading">
                                <p><span @click="delBox()">删除</span></p>
                                <div class="data-box">
                                   <img src="./../../assets/img/ppt.svg" class="icon">
                                    <p class="file-name">{{form.name}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="mu-text-field">
                            <i class="mu-text-field-icon mu-icon material-icons iconfont icon-biaoqian"></i>
                            <div class="mu-text-field-content">
                                <el-input v-model="inputs" placeholder="资源名称" size="small" @focus="focus" @blur="blur"></el-input>
                                <div><hr class="mu-text-field-line"> <hr class="mu-text-field-focus-line" :class="focunum==1?'focues':''"></div>
                            </div>
                        </div>
                        <div class="btn-wrapper">
                            <el-button type="info" size="small" @click="cancel()">取 消</el-button>
                            <el-button v-if="fileList.length==0" type="info" size="small" disabled>确 定</el-button>
                            <el-button v-else type="primary" size="small" @click="docadd()">确 定</el-button>
                        </div>
                    </div>
                </div>
            </el-drawer>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            istrue:1,
            drawer:false,
            login_acc:false,
            focunum:0,
            inputs:'',
            database:false,
            drawList:[],
            fileList:[],
            form:{},
            deletes:false,
        }
    },
    directives: {
        close: {
            inserted(el, binding, vnode) {
                window.onclick = function(e) {
                    if (!el.contains(e.target)) {
                        vnode.context.deletes = false;
                    }
                };
            }
        }
    },
    methods: {
        getMsgid(data){
            this.id = data
        },
        // 打开资料库
        drawers(){
            this.axios.doclist({
                token:this.$store.state.token,
                id:this.id
            }).then(res=>{
                if(res.data.code==0){
                    this.drawList = res.data.data
                    this.drawer = true
                }else{
                    
                }
            }).catch(err=>{

            })
        },
        // 下载
        download(item){
            window.open(item.url, "_blank");
        },  
        deleBtn(item){
            this.$confirm('删除后无法找回哦', '确定删除', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnClickModal:false
            }).then(() => {
                this.axios.docdel({
                    id:item.id,
                    token:this.$store.state.token
                }).then(res=>{
                    if(res.data.code==0){
                        this.drawers()
                    }
                }).catch(err=>{

                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        deletess(){
            this.deletes = true
        },
        //添加
        docadd(){
            this.axios.docadd({
                token:this.$store.state.token,
                id:this.id,
                name:this.inputs,
                url:this.form.response.data
            }).then(res=>{
                if(res.data.code==0){
                    this.$message.success('添加成功')
                    this.istrue = 1
                    this.fileList = []
                    this.drawers()
                }
            }).catch(err=>{

            })
        },
        // 上传文件
        handleAvatarSuccess(res,file,fileList){
            this.fileList = fileList
            this.form = file
            this.inputs = file.name
        },
        //删除上传文件
        delBox(){
            this.fileList = []
            this.inputs = ''
        },
        addBox(){
            this.istrue = 2
        },
        cancel(){
            this.istrue = 1
            this.fileList = []
        },
        // 获取焦点
        focus(){
            this.focunum = 1
        },
        // 失去焦点
        blur(){
            this.focunum = 0
        },
        mouseover(){
            this.login_acc = true
        },
        mouseout(){
            this.login_acc = false
        },
        getPath(){
            if(this.$route.name =='Lecturerend'){
                this.database=false
            }else if(this.$route.name == 'Detailsclassroom'){
                this.database=true
            }
        },
        // 安全退出
        exit(){
            this.$router.push('/login')
            localStorage.clear()
        },
        count(){
            this.$router.push({name:'AccountSettings'})
        },
    },
    watch:{
        $route(){
            this.getPath()
        }
    },
    mounted() {
        this.getPath()
    },
}
</script>
<style scoped>
@import './../../assets/css/teacher/index.css';
</style>
<style>
/* 资源库抽屉 */
.drawerBox .el-drawer__header{
    padding: 0 8px;
    height: 44px;
    line-height: 44px;
    background: #f1f6fe;
    color: #0e1726;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
}
.add_upload .el-upload{
    width: 100%;
}
.add_upload .el-upload-dragger{
    width: 100%;
    height: 140px;
    border: 1px dashed rgba(40, 120, 255, 0.5);
    background-color: rgba(40, 120, 255, 0.04);
}
.mu-text-field-content .el-input__inner{
    border: none;
    padding: 0;
}
</style>